<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Diego Andres Macias Arciniegas                                ###### -->
<!-- ###### @date: Enero 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div>


    <!-- content -->
    <v-container fluid>

      <template>

        <v-form v-model="formularioItemCotizacion.valid" style="display: inline-flex;">

          <v-text-field style="padding-right: 5px;" dense outlined v-model="formularioItemCotizacion.id" type="number"
            label="Id"></v-text-field>

          <v-text-field style="padding-right: 5px;" dense outlined v-model="formularioItemCotizacion.nit"
            label="NIT"></v-text-field>

          <v-text-field style="padding-right: 5px;" dense outlined v-model="formularioItemCotizacion.nombre"
            label="Nombre"></v-text-field>

          <v-text-field style="padding-right: 5px;" dense outlined v-model="formularioItemCotizacion.usuario"
            label="Usuario"></v-text-field>


        </v-form>

      </template>

      <template>

        <v-data-table dense fixed-header item-key="name" class="elevation-1 tabla-tools" :headers="tablaHistorico.headers"
          :loading="cargandoDatos" :items="tablaHistorico.body" :items-per-page="formularioItemCotizacion.itemsPorPagina"
          @update:items-per-page="(i) => formularioItemCotizacion.itemsPorPagina = i"
          :page="formularioItemCotizacion.page" @update:page="(p) => formularioItemCotizacion.page = p"
          :footer-props="footerProps" :server-items-length="formularioItemCotizacion.totalElements" height="61vh">

          <template v-slot:item.timestampCreacion="{ item }">
            {{ formatoFecha(item.timestampCreacion) }}
          </template>

          <template v-slot:item.estadoCotizacion="{ item }">
            {{ boleanoATextoBloqueo(item.estadoCotizacion) }}
          </template>

          <template v-slot:item.nombreComercial="{ item }">
            {{ boleanoATexto(item.nombreComercial) }}
          </template>

          <template v-slot:item.nombreGenerico="{ item }">
            {{ boleanoATexto(item.nombreGenerico) }}
          </template>

          <template v-slot:item.cums="{ item }">
            {{ boleanoATexto(item.cums) }}
          </template>

          <template v-slot:item.laboratorio="{ item }">
            {{ boleanoATexto(item.laboratorio) }}
          </template>

          <template v-slot:item.acciones="{ item }">
            <span title="Ver detalles y modificar">
              <a style="text-decoration:none" :href="`#/modules/tools/homologator/historico-homologacion/ver/${item.id}`" data-toggle="Ver">
                <v-icon>visibility</v-icon>
              </a>
            </span>
          </template>

        </v-data-table>

      </template>

    </v-container>

    <!-- notificaciones (alertas) para el usuario de las acciones realizadas -->
    <v-snackbar top v-model="alertas.estado" :timeout="alertas.tiempo" :color="alertas.color">
      {{ alertas.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="alertas.estado = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>


  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from 'vuex'
import moment from "moment";
import { Role } from "@/router/role.js";

// delay entre cada cambio de texto para los filtros
const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  data() {
    return {
      alertas: {
        estado: false,
        tiempo: 1 * 1000,
        color: 'red',
        text: ''
      },
      tab: 1,
      verMenu: true,
      roles: Role,
      userRoles: {},

      cargandoDatos: false,
      tablaHistorico: {
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          {
            text: 'NIT',
            align: 'start',
            sortable: false,
            value: 'nit',
          },
          {
            text: 'NOMBRE',
            align: 'start',
            sortable: false,
            value: 'nombre',
          },
          {
            text: 'TIPO PORTAFOLIO',
            align: 'start',
            sortable: false,
            value: 'tipoPortafolio',
          },
          {
            text: 'FECHA CREACIÓN',
            align: 'start',
            sortable: false,
            value: 'timestampCreacion',
          },
          {
            text: 'USUARIO',
            align: 'start',
            sortable: false,
            value: 'usuario',
          },
          {
            text: 'ESTADO',
            align: 'start',
            sortable: false,
            value: 'estadoCotizacion',
          },
          {
            text: 'NOMBRE COMERCIAL',
            align: 'start',
            sortable: false,
            value: 'nombreComercial',
          },
          {
            text: 'NOMBRE GENERICO',
            align: 'start',
            sortable: false,
            value: 'nombreGenerico',
          },
          {
            text: 'CUM',
            align: 'start',
            sortable: false,
            value: 'cums',
          },
          {
            text: 'LABORATORIO',
            align: 'start',
            sortable: false,
            value: 'laboratorio',
          },
          {
            text: '...',
            align: 'start',
            sortable: false,
            value: 'acciones',
          },
        ],
        body: [],
      },
      formularioItemCotizacion: {
        verFecha: false,
        valid: false,
        page: 1,
        pageCount: 0,
        itemsPorPagina: 10,
        id: '',
        nit: '',
        usuario: '',
        verOcultar: false,
        nombre: '',
        fecha: [],
        totalElements: 0
      },
      validacionFormulario: {
        validarNumeros: []
      },
      footerProps: {
        showFirstLastPage: true,
        "show-current-page": true,
        'items-per-page-text': 'Ítems por página',
        'items-per-page-options': [10, 20],
        'items-length': 100
      },
    }
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(['auth', 'notify', 'busy']),
  },
  methods: {
    ...mapMutations(['hideNotify', 'showSuccess', 'showBusy', 'hideBusy', 'snackbar']),
    soloNumeros() {
      this.formularioItemCotizacion.id = this.formularioItemCotizacion.id.replace(/\D/gi, '');
    },
    getItemsCotizaciones() {

      this.cargandoDatos = true;
      this.tablaHistorico.body = [];

      this.$http.get('msa-tools/api/item-cotizacion', {
        params: {
          size: this.formularioItemCotizacion.itemsPorPagina,
          page: this.formularioItemCotizacion.page - 1,
          id: this.formularioItemCotizacion.id,
          nit: this.formularioItemCotizacion.nit,
          usuario: this.formularioItemCotizacion.usuario,
          nombre: this.formularioItemCotizacion.nombre,
        }
      })
        .then(response => {
          this.tablaHistorico.body = response.data.content;
          this.formularioItemCotizacion.totalElements = response.data.totalElements;
          this.cargandoDatos = false;
        })
        .catch(error => {
          this.cargandoDatos = false;
          console.error(error)
          this.snackbar('Error al listar, por favor intentar nuevamente más tarde', 'error', 2000)
        })

    },
    boleanoATexto(valor) {
      return valor ? 'Si' : 'No'
    },
    boleanoATextoBloqueo(valor) {
      return valor ? 'Activo' : 'Bloqueado'
    },
    formatoFecha(valor) {
      return moment(valor).format("YYYY/MM/DD HH:mm")
    }
  },
  watch: {
    deep: true,
    'formularioItemCotizacion.page': function () {
      this.getItemsCotizaciones();
    },
    'formularioItemCotizacion.itemsPorPagina': function () {
      this.getItemsCotizaciones();
    },
    'formularioItemCotizacion.id': debounce(function () {
      this.formularioItemCotizacion.page = 1;
      this.getItemsCotizaciones();
    }, 500),
    'formularioItemCotizacion.nit': debounce(function () {
      this.formularioItemCotizacion.page = 1;
      this.getItemsCotizaciones();
    }, 500),
    'formularioItemCotizacion.nombre': debounce(function () {
      this.formularioItemCotizacion.page = 1;
      this.getItemsCotizaciones();
    }, 500),
    'formularioItemCotizacion.usuario': debounce(function () {
      this.formularioItemCotizacion.page = 1;
      this.getItemsCotizaciones();
    }, 500),
    'formularioItemCotizacion.fecha': function () {
      if (this.formularioItemCotizacion.fecha === null) {
        this.getItemsCotizaciones();
      }
      if (this.formularioItemCotizacion.fecha && this.formularioItemCotizacion.fecha.length == 2) {
        this.formularioItemCotizacion.page = 1;
        const fechas = this.formularioItemCotizacion.fecha;
        const bandera = moment(fechas[0]).isAfter(moment(fechas[1]))
        if (bandera) {
          this.alertas.estado = true;
          this.alertas.text = 'La fecha iniciar no puede ser mayor a la final';
          this.formularioItemCotizacion.fecha = []
        } else {
          this.getItemsCotizaciones();
        }
      }
    }
  },
  mounted() {
    this.getItemsCotizaciones();
  },
  components: {
  }
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped lang="scss">
.boton-tools {
  padding: 0px 10px 0px 10px !important;

}

.icono-tools {
  align-items: baseline;
  font-size: 56px !important;
  margin-top: 0px !important;
}

.theme--light.v-label {
  color: black !important;
}

.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  height: 100px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 18px 0;
}

.result-icon {
  padding-right: 7px;
}

.toolbar {
  height: 60px !important;
}

.ui.dropdown .menu {
  position: static !important;
}

.help-text {
  font-style: italic;
}

.texto-negro {
  color: black !important;
  position: absolute;
}

.fila-seleccionada {
  background-color: #d2f3d5 !important;
}

.ocultar-elemento {
  display: none;
}

.boton-descarga {
  height: 22px !important;
}

.padding-filtro {
  padding: 0px;
}

::v-deep .tabla-tools div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tabla-tools div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .tabla-tools div table tbody tr td {
  font-size: 0.7rem !important;
}

::v-deep .tabla-tools div table tbody tr td span div {
  font-size: 0.7rem !important;
}

::v-deep .tabla-tools .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
  padding: 0px 1px;
}


::v-deep .seleccionar-multiple .v-select__selection--comma {
  max-width: 98% !important;
}

.modificado-tabla {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.v-application .texto-cabecera p {
  margin-bottom: 0px !important;
}

.hijo {
  font-size: 0.8rem;
}

a {
  text-decoration: none;
}
</style>